<template>
  <div class="d-flex justify-content-center">
    <b-row class="mt-3 col-8">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{ is_editing ? $t('creator.editOption') : $t('creator.addOption') }}</h1>
        <p>{{ $t('creator.optionDesc') }}</p>

        <div>
            <b-form-group :label="$t('creator.titleStep')" label-for="input-title" class="form-group-layout">
            <b-form-input :state="stateTitle(option)" aria-describedby="input-live-help invalid-feedback-title" id="input-title" v-model="option.title" trim @keyup.enter="option.title.length > 0 ? save(): ''"></b-form-input>
            <b-form-invalid-feedback id="invalid-feedback-title">
                <span v-if="option.title.length > 100">{{ $t('creator.100charactersDeal') }}</span>
            </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group :label="$t('creator.descriptionStep')" label-for="input-description" class="form-group-layout">
            <b-form-textarea :state="stateDescription(option)" aria-describedby="input-live-help invalid-feedback-description" id="input-description" v-model="option.description" rows="3"></b-form-textarea>
            <b-form-invalid-feedback id="invalid-feedback-description">
                <span v-if="option.description.length > 400">{{ $t('creator.400charactersOption') }}</span>
            </b-form-invalid-feedback>
            </b-form-group>
            <b-row>
              <b-col class="col-12">
                <b-form-group v-if="!is_brandme_talent" :label="$t('creator.additionalPrice')" label-for="input-price" class="col-12 col-md-6 form-group-layout">
                    <b-input-group prepend="$" id="input-price" :class="!statePrice(option.price) ? 'is-invalid' : 'is-valid'" @keyup.enter="option.price.length > 0 ? save(): ''">
                      <component
                        :is="cleave"
                        v-model="option.price"
                        :options="options_number"
                        :class="`form-control ${!statePrice(option.price) ? 'is-invalid' : 'is-valid'}`"
                        :raw="false"
                      />
                    </b-input-group>
                    <div class="invalid-feedback mt-1" v-if="!statePrice(option.price)">
                        {{$t('creator.price_around_1')}}
                    </div>
                </b-form-group>
                <b-form-group v-else v-slot="{ ariaDescribedby }">
                  <b-form-radio v-model="type_price" :aria-describedby="ariaDescribedby" value="public" stacked class="full-radio">
                    <div class="">
                      <b-form-group label-for="input-price" class="form-group-layout">
                        <b-input-group prepend="$" id="input-price" :class="!statePrice(option.price) ? 'is-invalid' : 'is-valid'" @keyup.enter="option.price.length > 0 ? save(): ''">
                          <component
                            :is="cleave"
                            v-model="option.price"
                            :options="options_number"
                            :class="`form-control ${!statePrice(option.price) ? 'is-invalid' : 'is-valid'}`"
                            :raw="false"
                          />
                        </b-input-group>
                        <div class="invalid-feedback mt-1" v-if="type_price === 'public' && !statePrice(option.price)">
                            {{$t('creator.price_around_1')}}
                        </div>
                      </b-form-group>
                    </div>
                  </b-form-radio>

                  <b-form-radio v-model="type_price" :aria-describedby="ariaDescribedby" value="hidden" stacked class="mt-3 full-radio">
                    <div class="">
                      <b-form-group label-for="input-price" class="form-group-layout w-100">
                        <b-form-rating v-model="range_price" no-border size="lg">
                          <template #icon-empty>
                            <b-img src="@/assets/images/icons/dollar-empty.svg" class="icon-svg"/>
                          </template>
                          <template #icon-full>
                            <b-img src="@/assets/images/icons/dollar-full.svg" class="icon-svg"/>
                          </template>
                        </b-form-rating>
                      </b-form-group>
                    </div>
                  </b-form-radio>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col-12">
                <b-form-group :label="$t('creator.deliveryStep')" label-for="input-delivery" class="col-12 col-md-12 form-group-layout">
                  <component :is="vue_select" :options="days_delivery_options" id="input-delivery" v-model="option.days_delivery">
                  </component>
                </b-form-group>
              </b-col>
            </b-row>
        </div>
        <b-col class="col-12 d-flex justify-content-center">
          <b-button v-if="is_editing" class="my-2 mr-1" variant="danger" @click.once="deleteOption()">{{ $t('creator.delete') }}</b-button>
          <b-button variant="blue-button-next-step" class="blue-button-next-step my-2" :disabled="!isPassingValidations()" @click.once="save()">{{has_slug ? $t('creator.savePreview') : $t('creator.butonNext')}}</b-button>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BFormInvalidFeedback,
  BFormRadio,
  BFormRating,
  BImg
} from 'bootstrap-vue';
import proposals_services from '@/services/proposals';
import { getUserData } from '../../../../libs/utils/user';

export default {
  name: 'optionsComponent',
  components: {
    BRow,   
    BCol,
    BButton,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BInputGroup,
    BFormInvalidFeedback,
    BFormRadio,
    BFormRating,
    BImg
  },
  data() {
    return {
      options_number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand'
      },
      days_delivery_options: ['0', '1', '2', '3', '7', '10'],
      proposal: {},
      option: Object.keys(this.option_selected).length > 0 ? {...this.option_selected} : {
        price: 3,
        title:  this.$t('creator.anyTitle'),
        description: this.$t('creator.anyDescription'),
        days_delivery: '2',
        range_price: null
      },
      cleave: null,
      vue_select: null,
      is_brandt_talent: false,
      range_price: null,
      type_price: 'public'
    }
  },
  props: {
    option_selected: {
      type: Object,
      default: () => {
        return {}
      }
    },
    is_editing: {
      type: Boolean,
      default: false,
    }
  },
  async mounted() {
    this.cleave = (await import('vue-cleave-component')).default;
    this.vue_select = (await import('vue-select')).default;
  },
  created() {
    this.getData();
    this.checkBrandmeTalent()
  },
  computed: {
    has_slug() {
      return this.$route.params.slug
    }
  },
  methods: {
    checkBrandmeTalent() {
      const user = getUserData()
      const permissions = JSON.parse(localStorage.getItem('permissions'))
      const has_permission_brandme_talent = permissions.indexOf('brandme_talent')
      this.is_brandme_talent = user.is_superuser || has_permission_brandme_talent > -1
    },
    stateTitle(option) {
      if (option.title) return option.title.length > 0 && option.title.length < 100;
      else return false
    },
    stateDescription(option) {
      if (option.description) return option.description.length > 0 && option.description.length < 500;
      else return false
    },
    statePrice(price) {
      const real_price = typeof price === 'string' ? parseInt(price.replace(/,/g, '')) : price
      return real_price >= 1 && real_price < 9999999
    },
    getData() {
      this.proposal = this.$route.params.proposal;

      if (this.option.is_brandme_talent && parseFloat(this.option.price) === 0) {
        this.type_price = 'hidden'
        this.range_price = this.option.range_price
      }
    },
    save() {
      if (this.isPassingValidations) {
        if (typeof this.option.price === 'string' || isNaN(this.option.price)) this.option.price = parseInt(this.option.price.split(',').join(''));
        if (this.type_price === 'hidden') {
          this.option.range_price = this.range_price;
          this.option.price = 0;
        } else {
          delete this.option.range_price
        }
        console.log(this.option);
        if (this.option.uuid) {
          proposals_services.updateElement(this.proposal.uuid, this.option.uuid, this.option, 'option').then((response) => {
            this.$emit('update_proposal', response)
          });
        } else {
          proposals_services.addOptionOrRequest(this.proposal.uuid, {options: [this.option]}, 'option').then((response) => {
            this.$emit('update_proposal', response)
          })
        }
      }
    },
    deleteOption() {
      proposals_services.deleteElement(this.proposal.uuid, this.option.uuid, 'option').then((response) => {
        this.$emit('update_proposal', response)
      });
    },
    isPassingValidations() {
      const is_passing_all = [];
      is_passing_all.push(this.stateTitle(this.option))
      is_passing_all.push(this.stateDescription(this.option))

      if (this.type_price === 'public') {
        is_passing_all.push(this.statePrice(this.option.price))
      } else {
        is_passing_all.push(this.range_price > 0)
      }

      return is_passing_all.every((item) => item === true);
    },
  },
  watch: {
    type_price(new_val) {
      if (new_val === 'public') {
        this.range_price = null;
      }
    },
    range_price() {
      this.type_price = 'hidden'
    }
  }
}
</script>
<style>
.icon-svg {
  width: 20px;
}
.form-group-layout > label {
  text-align: left;
}
.btn-group-toggle {
  display:initial;
}
#input-delivery {
  background-color: white;
}
#input-delivery .vs__clear {
  display: none;
}
</style>